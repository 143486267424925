<template>
  <div>
    <Create
      :create="createItem"
      :close="closeCreate"
      :descSingular="descSingular"
    ></Create>
    <Edit
      :item="currentItem"
      :access="currentAccess"            
      :update="updateItem"      
      :close="closeEdit"
      :descSingular="descSingular"
    ></Edit>
    <div>
      <div class="content">
        <div class="content-wrapper">
          <Loading :isActive="loading"></Loading>
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="descPlural">
              <div class="filters row" style="margin-top:0">
                <div class="col-1-1">
                  <div class="pull-right">
                    <button v-on:click="openCreate" class="button button-success button-block">
                      <fa-icon icon="plus" />
                      Criar
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Imagem Principal</th>
                      <th>Título</th>
                      <th>Ativo</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items">
                      <td>
                        <img class="img-thumbnail" :src="item.imagem_principal"/>
                      </td>
                      <td>{{ item.titulo }}</td>
                      <td>
                        <span class="label success" v-if="item.ativo">Ativo</span>
                        <span class="label error" v-else>Inativo</span>
                      </td>
                      <td class="actions">
                        <button type="button" class="button button-primary" v-on:click="openEdit(item)"><fa-icon icon="pencil-alt" /></button>
                        <!--<button type="button" name="button" v-on:click="deleteConfirm(item.id)" class="button button-info"><fa-icon icon="eye" /></button>-->
                        <button type="button" name="button" v-on:click="deleteConfirm(item.id)" class="button button-error"><fa-icon icon="trash-alt" /></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CTab>
          </CTabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import clone from 'just-clone';
import { get, post, put, del } from '@/helpers/apiRequest';
import Create from './Create';
import Edit from './Edit';

export default {
  name: 'postagens',
  components: {
    Create,
    Edit,
  },
  data() {
    return {
      descPlural: '',
      descSingular: '',
      loading: false,
      items: [],      
      modulos: [],
      currentItem: {},
      currentAccess: false,
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_POSTAGEM'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadPostagens() {
      this.loading = true;
      get('/admin/site-postagens')
        .then((json) => {
          this.items = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    openCreate() {      
      this.$modal.show('postagemCreate');
    },
    closeCreate() {
      this.$modal.hide('postagemCreate');
    },
    createItem(item) {
      this.loading = true;
      post('/admin/site-postagens', item).then((json) => {
        this.items.push(json);
        this.loading = false;
        this.closeCreate();        
        this.loadPostagens();
      });      
    },
    openEdit(item, showLoading = true) {
      this.currentItem = clone(item);
      if (showLoading) {
        this.loading = true;
      }
      get(`/admin/site-postagens/${this.currentItem.id}`).then((json) => {
        this.currentAccess = json;
        if (showLoading) {
          this.loading = false;
          this.$modal.show('postagemEdit');
        }
      });
    },
    closeEdit() {
      this.$modal.hide('postagemEdit');
    },
    updateItem() {
      const { id } = this.currentItem;
      put(`/admin/site-postagens/${id}`, this.currentItem)
        .then((json) => {
          this.closeEdit();
          this.loadPostagens();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });          
        })
        .catch((error) => {
          this.$swal({
            title: 'Falha ao Salvar',
            text: error.message,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            showConfirmButton: false,
            icon: 'error',
          });
        });
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover ?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/site-postagens/${id}`)
              .then((json) => {
                this.loading = false;
                this.loadPostagens();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },    
  },
  beforeMount() {
    this.loading = true;
    this.loadPostagens();    
    this.loadDescricaoModulo();
  },
};
</script>
